import React from 'react';


function Footer()  {
    return (
        <>
        <div className="card">
            <div className="card-body footer">
                © 2023 Next Gen Code
            </div>
        </div>
        </>
    );
}

export default Footer;